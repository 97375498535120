<template>
    <div>
        <ul class="noticelist_ul">
            <li class="noticelist_li" v-for="(notice) in getNoticelist" :key="notice.commonNoticeId"
                @click="goDetail(notice.commonNoticeId)">
                <div class="taging">
                    <span class="notice_tag">공지</span>
                </div>
                <div class="notice_contents">
                    <p class="notice_contents_title">{{ notice.commonNoticeTitle }}</p>
                    <p class="notice_contents_content" v-html="notice.commonNoticeContents"></p>
                </div>
                <div class="notice_register_date">
                    {{ notice.reigisterFormat }}
                </div>
            </li>
        </ul>
        <infinite-loading v-if="isLoad" @infinite="infiniteHandler" :identifier="infiniteId" spinner="waveDots">
            <template #no-more>
                <span></span>
            </template>
            <template #no-results>
                <span></span>
            </template>
        </infinite-loading>
    </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
export default {
  name: 'NoticeListComponent',
  components: {
    'infinite-loading': InfiniteLoading
  },
  data () {
    return {
      page: 1,
      limit: 5,
      originalList: [],
      noticeList: [],
      infiniteId: new Date(),
      isLoad: false
    };
  },

  created () {
    this.getNoticeList();
  },

  mounted () {

  },

  methods: {
    infiniteHandler ($state) {
      const nextStrat = this.page * this.limit;

      const slice = this.originalList.slice(nextStrat, nextStrat + this.limit);

      if (slice.length > 0) {
        setTimeout(() => {
          this.noticeList = [...this.noticeList, ...slice];
          this.page += 1;
          $state.loaded();
        }, 1000);
      } else {
        setTimeout(() => {
          $state.complete();
        }, 1000);
      }
    },
    /**
     * @description 공지사항 Top List
     * @author CHOI DAE GEON
     */
    getNoticeList () {
      axios
        .post('/api/menu/getCommonNoticeList', { commonNoticeService: "web" })
        .then(res => {
          this.originalList = res.data.result;
          this.noticeList = this.originalList.slice(0, 5);
          this.isLoad = true;
        })
        .catch(err => {
          console.log(err);
          this.noticeList = [];
        }).finally(() => {
          this.isLoad = true;
        });
    },
    /**
     * @description 공지사항 상세
     * @param {*} noticeId
     */
    goDetail (noticeId) {
      this.$router.push({ path: `/noticedetail/${noticeId}` }).catch(() => { });
    }
  },

  computed: {
    getNoticelist () {
      return this.noticeList.map((notice) => {
        const registerFormatDate = new Date(notice.registDate);
        const registerYyyy = registerFormatDate.getFullYear().toString().slice(2, 4);
        const registerMm = registerFormatDate.getMonth() + 1 > 9 ? registerFormatDate.getMonth() + 1 : `0${registerFormatDate.getMonth() + 1}`;
        const registerDate = registerFormatDate.getDate() > 9 ? registerFormatDate.getDate() : `0${registerFormatDate.getDate()}`;
        const registerStartTime = registerFormatDate.getHours() > 9 ? registerFormatDate.getHours() : `0${registerFormatDate.getHours()}`;
        const registerEndTime = registerFormatDate.getMinutes() > 9 ? registerFormatDate.getMinutes() : `0${registerFormatDate.getMinutes()}`;
        const reigisterFormat = `${registerYyyy}.${registerMm}.${registerDate} ${registerStartTime}:${registerEndTime}`;
        return {
          commonNoticeAction: notice.commonNoticeAction,
          commonNoticeChannel: notice.commonNoticeChannel,
          commonNoticeContents: notice.commonNoticeContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>"),
          commonNoticeId: notice.commonNoticeId,
          commonNoticePopup: notice.commonNoticePopup,
          commonNoticeSummary: notice.commonNoticeSummary ? notice.commonNoticeSummary.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>") : "",
          commonNoticeTitle: notice.commonNoticeTitle,
          commonNoticeTop: notice.commonNoticeTop,
          commonNoticeWriter: notice.commonNoticeWriter,
          endDate: notice.endDate,
          registDate: notice.registDate,
          startDate: notice.startDate,
          reigisterFormat
        };
      });
    }
  }
};
</script>
<style>
.notice_contents_content p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 22px;
  display: none;
}

.notice_contents_content p:first-child {
  display: inline-block;
}
</style>
<style scoped>
/*############################ noticelist_ul ############################*/

.noticelist_ul {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noticelist_li:first-child {
  padding-top: 32px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.noticelist_li {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}

.taging {
  width: 90px;
  display: flex;
  align-items: center;
}

.notice_contents_content p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 22px;
}

.notice_tag {
  padding: 2px 16px;
  background: var(--ffffff-12, rgba(255, 255, 255, 0.12));
  border-radius: 100px;
  opacity: 0.97;
  font-size: 16px;
  line-height: 1.6;
  color: var(--white);
}

.notice_contents {
  width: 720px;
}

.notice_contents_title {
  font-size: 20px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.87);
  font-weight: 500;
  word-break: keep-all;
}

.notice_contents_content {
  font-size: 16px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.60);
  font-weight: 400;
  word-break: keep-all;
}

.notice_register_date {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.60);
  font-weight: 500;
  line-height: 32px;
  display: flex;
  align-items: center;
}

/*############################ 반응형 ############################*/
@media all and (max-width:1024px) {
  .noticelist_li {
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .notice_contents {
    width: 100%;
    margin-top: 16px;
  }
}

/*######################## 반응형 처리 ~500px ########################*/
@media all and (max-width: 500px) {
  .noticelist_title {
    font-size: 18px;
  }

  .noticelist_li {
    padding: 0 8px;
    padding-top: 20px;
    padding-bottom: 21px;
  }

  .notice_tag {
    font-size: 14px;
  }

  .notice_contents_title {
    font-size: 16px;
  }

  .notice_contents_content {
    font-size: 14px;
  }

  .notice_register_date {
    padding-top: 12px;
    font-size: 14px;
  }
}
</style>

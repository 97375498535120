<template>
    <div>
        <div id="header" class="fix">
            <div class="hd_inr cf">
                <div class="logo">
                    <router-link :to="{ name: 'Home' }">
                        <img src="/media/img/logo.svg" alt="어플레이즈">
                        <!-- <img src="/media/img/logo_beta.svg" alt="어플레이즈"> -->
                    </router-link>
                </div>
                <div id="nav">
                    <div class="gnb">
                        <ul class="cf">
                            <li class="gnb_join"><router-link :to="{ name: 'Login' }">로그인/회원가입</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <section class="notice_container">
            <h1 class="noticelist_title">공지사항</h1>
            <div class="noticelist_list">
                <notice-list />
            </div>
        </section>
    </div>
</template>
<script>
import NoticeListComponent from '@/components/notice/NoticeListComponent.vue';
import $ from 'jquery';
export default {
  name: 'NoticeListView',
  components: {
    'notice-list': NoticeListComponent
  },
  data () {
    return {

    };
  },

  mounted () {
    $(window).scroll(() => {
      var scrollT = $(window).scrollTop();
      if (scrollT > 60) {
        $("#header.fix").addClass('active');
      } else {
        $("#header.fix").removeClass('active');
      }
    });

    if (window.innerWidth <= 820) {
      $('#header .logo img').attr('src', '/media/img/logo_m.png');
    } else {
      $('#header .logo img').attr('src', '/media/img/logo_white.svg');
    }
  },

  methods: {

  }
};
</script>

<style src="@/assets/css/style.css"></style>
<style scoped>
.notice_container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 132px;
}

.noticelist_title {
  color: var(--white);
  font-size: 38px;
  font-weight: 700;
  line-height: 52px;
}

.noticelist_list {
  margin-top: 52px;
}

/*############################ 반응형 ############################*/
@media all and (max-width:1200px) {
  .notice_container {
    width: 90%;
  }
}

/*######################## 반응형 처리 ~500px ########################*/
@media all and (max-width: 500px) {
  .notice_container {
    padding-top: 15.833vw;
  }

  .noticelist_list {
    margin-top: 13px;
  }

  .noticelist_title {
    font-size: 18px;
    line-height: 1.2;
  }
}
</style>
